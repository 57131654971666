import React from 'react';

import './App.css';
import 'jquery-ui-dist/jquery-ui';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import LoginUtils from './loginUtils';
import Accedi from './Accedi';

export default class TestAWSCP extends React.Component {

  iframe() {
      return {
        __html: '<iframe src="aws/index" width="100%" height="550"></iframe>'
    }
  }
  
  constructor(props){  
    super(props);

    this.showHome = this.showHome.bind(this);
    this.showLogin = this.showLogin.bind(this);

    let sUserName = LoginUtils.getUserName();
    if(sUserName==null) {
      this.state = {
        userName: '',
        showLogin: false
      };
    } else {
      this.state = {
        userName: sUserName,
        showLogin: true
      };
    }
  }

  showHome() {
    let sUserName = LoginUtils.getUserName();
    if(sUserName==null) {
      this.state = {
        userName: '',
        showLogin: false
      };
    } else {
      this.state = {
        userName: sUserName,
        showLogin: true
      };
    }

    this.setState(prevState => ({
      showLogin: false
    }));
  }

  showLogin() {
    this.setState(prevState => ({
      showLogin: true
    }));
  }

  startTest = () => {
    this.setState({
      testStarted: true
    });
  }

  render() {

    return (
        <div>
          <link href="assets/css/theme.css" rel="stylesheet" />
          <link rel="stylesheet" href="//code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css" />

        <Navbar expand="lg" className="lg bg-light" fixed="top" collapseOnSelect >
          <Container>
            <Navbar.Brand href="/"><img src="assets/img/logo.png" height={31} alt="logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto" style={{marginTop: 8}}>
                <Nav.Link href="/" style={{marginLeft: 18}}>Home</Nav.Link>
                { !this.state.showLogin &&
                  <Nav.Link href="#" id="registrati" className="btn btn-warning ms-3 nav-link" type="button" onClick={this.showLogin}>Accedi</Nav.Link>
                }
                { this.state.showLogin &&
                  <Accedi doSignOut={this.showHome} userName={this.state.userName} label={"Esci"} />
                }
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        
        { !this.state.testStarted &&
          <section>
              <div className="container pt-2 pb-12">
                <div className="row d-none d-lg-block" />
                <div className="row align-items-left">
                  <div className="col-md-8 text-md-start text-left pt-5 pb-10" id="divProgetto">
                    <h1 className="mb-4 fs-9 fw-bold d-none d-lg-block">AWS Certified Cloud Practitioner</h1>
                    <h1 className="mb-4 fs-3 pt-9 fw-bold d-lg-none">AWS Certified Cloud Practitioner</h1>
                    <div className="lead text-secondary">
                    L'esame è composto da <b>65 domande</b> a risposta singola o multipla, a cui rispondere entro un tempo massimo di <b>90 minuti</b>.<br/>
                    E' possibile sostenere l'esame per la certificazione AWS Certified Cloud Practitioner presso un centro di certificazione Pearson VUE oppure online con un supervisore.  
                    </div>
                    <div className="pt-4 lead text-secondary">
                      <button id="inizia" className="btn btn-primary fw-medium py-1" onClick={this.startTest}>Inizia</button>
                    </div>
                    <div className="pt-4">
                      
                    </div>
                  </div>
                  <div className="col-md-2 text-end d-none d-lg-block"><img className="pt-7 pt-md-6 img-fluid" src="aws/assets/images/AWS-Certified-Cloud-Practitioner_badge.png" alt="" /></div>
                </div>
              </div>
            </section>
        }
        { this.state.testStarted && 
          <section className="pt-100">
              <div className="container">
                <div dangerouslySetInnerHTML={this.iframe()} ></div>
              </div>
          </section>
        }

        <section className="pt-5">
          <div className="container">
            <div className="row border-top border-top-secondary pt-7">
              <div className="col-3"><a className="link-900 text-secondary text-decoration-none" href="#!">Chi siamo</a></div>
              <div className="col-3"><a className="link-900 text-secondary text-decoration-none" href="#!">Contatti</a></div>
              <div className="col-3"><a className="link-900 text-secondary text-decoration-none" href="#!">Termini e condizioni</a></div>
              <div className="col-3"><a className="link-900 text-secondary text-decoration-none" href="#!">Informativa privacy</a></div>
            </div>
          </div>
        </section>
          
          <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Volkhov:wght@700&display=swap" rel="stylesheet" />
        </div>
      );
  }
    
}