import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './index.css';
import '@aws-amplify/ui-react/styles.css';

import App from './App';
import Accedi from './Accedi';
import TestAWSCP from './TestAWSCP';

export default function Index() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<App />} />
        <Route path="/accedi" element={<Accedi />} />
        <Route path="/test-aws-cp" element={<TestAWSCP />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);
