function ComeFunziona() {
  return (
        <section className="pt-6 pb-12" id="come-funziona">
            <div className="bg-holder z-index--1 bottom-0 d-none d-lg-block" style={{backgroundImage: 'url(assets/img/category/shape.png)', opacity: '.5'}}>
            </div>

            <div className="container">
                <h1 className="pt-1 fs-9 fw-bold mb-4 text-center">Come funziona</h1>
                <div className="row">
                <div className="col-lg-3 col-sm-6 mb-2"> <img className="mb-3 ms-n3" src="assets/img/category/icon1.png" width={75} alt="Segui un percorso di formazione" />
                    <h4 className="mb-3">Formazione</h4>
                    <p className="mb-0 fw-medium text-secondary">Segui un percorso di formazione e comincia le nostre simulazioni per verificare il tuo apprendimento</p>
                </div>
                <div className="col-lg-3 col-sm-6 mb-2"> <img className="mb-3 ms-n3" src="assets/img/category/icon2.png" width={75} alt="Prove di esame" />
                    <h4 className="mb-3">Prove di esame</h4>
                    <p className="mb-0 fw-medium text-secondary">Utilizza le nostre simulazioni d'esame per prepararti all'esame</p>
                </div>
                <div className="col-lg-3 col-sm-6 mb-2"> <img className="mb-3 ms-n3" src="assets/img/category/icon3.png" width={75} alt="Esame" />
                    <h4 className="mb-3">Esame</h4>
                    <p className="mb-0 fw-medium text-secondary">Iscriviti all'esame online e continua ad esercitarti con le simulazioni in attesa della data d'esame</p>
                </div>
                <div className="col-lg-3 col-sm-6 mb-2"> <img className="mb-3 ms-n3" src="assets/img/category/icon4.png" width={75} alt="Certificazione" />
                    <h4 className="mb-3">Certificazione</h4>
                    <p className="mb-0 fw-medium text-secondary">Sostieni l'esame e ottieni la tua certificazione</p>
                </div>
                </div>
            </div>
        </section>
    );
}

export default ComeFunziona;
