import React from 'react';

import './App.css';
import 'jquery-ui-dist/jquery-ui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import LoginUtils from './loginUtils';

import $ from 'jquery';

class Crediti extends React.Component {

  constructor(props){  
    super(props);

    let _this = this;

    this.state = {
      crediti: 0
    };

    let userName = LoginUtils.getUserName();
    if(userName != null) {

      let crediti = 0;

      $.ajax({
        type : 'GET',
        url : 'https://21aw3haiol.execute-api.eu-north-1.amazonaws.com/crediti',
        dataType : 'json',
        data: {
          username: userName
        },
        success : function (data) {
            $.each( data, function( i, itemData ) {
              crediti += itemData.valore;
            });
            _this.setState({
              crediti: crediti
            });
        },
        error : function ( XMLHttpRequest, textStatus, errorThrown) {
            var err = "An error has occured: " + errorThrown;
            console.log(err);
            _this.setState({
              crediti: 0
            });
        }
      });
    }
  }

  render() {
    return (
      <span className="loginBar">
        <FontAwesomeIcon icon={faCoins} />
        <b> {this.state.crediti} crediti</b>
      </span>
    ); 
  }
}

export default Crediti;