import './App.css';
import 'jquery-ui-dist/jquery-ui';

import { Amplify } from "aws-amplify";
import { signOut } from "aws-amplify/auth"
import { fetchAuthSession } from '@aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';

import { withAuthenticator, Button } from '@aws-amplify/ui-react';
import { Hub } from 'aws-amplify/utils';
import React, { useEffect } from 'react';

import awsconfig from './aws-exports';
import LoginUtils from './loginUtils';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Crediti from './Crediti';

Amplify.configure(awsconfig);

function Accedi( { doSignOut, label, userName } ) {

  async function handleSignOut() {
    await signOut();
    await doSignOut();
  }

  useEffect(() => {
    // Subscribe to auth events
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log('A new auth event has happened: ', event);
      switch (event) {
        case 'signedIn':
          setUsernameAndToken();
          break;
        case 'signedOut':
          LoginUtils.logout();
          break;
        default:
          break;
      }
    });
  }, []);

  const setUsernameAndToken = async () => {
    try {
      const user = await getCurrentUser();
      const session = await fetchAuthSession();

      LoginUtils.login(user.signInDetails.loginId, session.tokens.accessToken.toString())
    }
    catch (e) { console.log(e); }
  };

  return (
    <div>
      <Row>
        <Col md={2}>
          <Button className="btn btn-warning ms-3 nav-link" type="button" onClick={handleSignOut}>
            {label}
          </Button>
        </Col>
        <Col md={6}>
          <span className="loginBar">{userName}</span>
        </Col>
        <Col md={4}>
          <Crediti />
        </Col>
      </Row>
    </div>
  )
}

export default withAuthenticator(Accedi);