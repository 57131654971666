class LoginUtils {

    static login(username, token) {
        localStorage.setItem("token", token);
        localStorage.setItem("username", username);
    }

    static logout() {
        localStorage.removeItem("token")
        localStorage.removeItem("username");
    }

    static getUserToken() {
        return localStorage.getItem("token");
    }
    
    static getUserName() {
        return localStorage.getItem("username");
    }

}

export default LoginUtils;